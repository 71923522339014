<template>
  <el-card class="page-card">
    <slot name="header" class="clearfix">
      <el-button type="primary" @click="add" v-if="max === 0 || count < max"
        >{{ $t("common.add") }} {{ $t(name) }}</el-button
      >
      <el-descriptions v-else :title="$t(name)"></el-descriptions>
    </slot>
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :refactor="tableDataRefactor"
      :req="tableFunReq"
      @edit="edit"
      @hidden="hidden"
      @backflow="backflow"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { EditSpu, GetSpuList } from "@/utils/api";
import {formatDataTime} from "@/utils"
export default {
  props: {
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiList },
  data() {
    return {
      editing: false,
      tableColumn: [
        // 图片
        {
          type: "image",
          name: "img",
          label: "productlist.img",
        },
        // 产品名称
        {
          name: "spuName",
          label: "productlist.spuname",
        },
        // 最低价格
        {
          type: "price",
          name: "minPrice",
          label: "productlist.minPrice",
          width: 110,
        },
        // 最高价格
        {
          type: "price",
          name: "maxPrice",
          label: "productlist.maxPrice",
          width: 110,
        },
        // 分类
        {
          name: "categoryNameList",
          label: "productlist.category",
        },
        // 隐藏
        {
          type: "switch",
          name: "hidden",
          label: "productlist.hidden",
        },
        // 创建时间
        {
          name: "createAt",
          label: "content.exchaterate.createAt",
          formatFn: formatDataTime,
          width:110
        },
      ],
      tableFun: GetSpuList,
      tableFunReq: {
        orderKey: "",
        pageSize: this.max || 10,
      },
      // 表格中的分类
      tableDataRefactor: (list) => {
        return list.map((v) => {
          return {
            ...v,
            categoryNameList: v.categoryList
              .map((c) => c.categoryName)
              .join(" -> "),
          };
        });
      },
      count: 0,
    };
  },
  mounted() {
    // console.log('this:', this);
  },
  methods: {
    // 添加
    add() {
      this.$router.push({
        path: "/product/edit",
      });
    },
    // 编辑
    edit(rowData) {
      console.log("edit-rowData:", rowData);

      this.$router.push({
        path: "/product/edit",
        query: {
          id: rowData.id,
        },
      });
    },
    // 隐藏
    hidden(e, rowData, index) {
      console.log("hidden:", e, rowData, index);
      this.iEditBanner(rowData.id, { hidden: e }).then(() => {
        this.$message({
          type: "success",
          message: this.$t("common.optSuccess"),
        });
        this.$refs["list"].getList();
      });
    },
    iEditBanner(id, params) {
      return EditSpu({
        id,
        ...params,
      });
    },
    // 分页
    backflow(count) {
      this.count = count;
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>